import { MainScreen } from './main';
import { InfoPage } from './info';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          <Route exact path='/' element={<MainScreen />} />
          <Route path='/info' element={<InfoPage />} />
        </Routes>
      </Router>
    </AnimatePresence>
  );
}

export default App;
