import { MyComponent } from './client';
import { Component } from 'react';
import { InfoPage } from './info';
import { useState } from 'react';
import { Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AnimatedPage, { AnimationLayout } from './AnimationLayout';
import Loader from './load';

async function delay(ms) {
  // return await for better async stack trace support in case of errors.
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export class MainScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }
  componentWillMount() {
    this.loading();
  }

  //turn off the button if we tell the site to
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const showButton = urlParams.get('showbutton');
    if (showButton == 'false') {
      document.getElementById('cornerButton').style.visibility = 'hidden';
    }
  }

  loading = async () => {
    await delay(1500);
    this.setState({
      loaded: true,
    });
    console.log('Loaded:' + this.state.loaded);
    this.render();
  };

  render() {
    return (
      <AnimatedPage>
        <div id='full'>
          <div id='cornerButton'>
            <Link to='/info' id='buttonText'>
              ABOUT
            </Link>
          </div>

          <MyComponent></MyComponent>
        </div>
      </AnimatedPage>
    );
  }
}
