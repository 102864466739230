import { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './index.css';
import { createClient } from '@supabase/supabase-js';
import Loader from './load';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';

const googleID = 'G-R40329P9VW';

ReactGA.initialize(googleID);
const supabase = createClient(
  'https://glkmsstwzynytciznsqo.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imdsa21zc3R3enlueXRjaXpuc3FvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODkxMzkyMjgsImV4cCI6MjAwNDcxNTIyOH0.vBsoadp8AwOISjY3VIO4yuJsPUhDEdbxCwe7JZ9IBaw'
);

async function delay(ms) {
  // return await for better async stack trace support in case of errors.
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export class MyComponent extends Component {
  state = {
    activeUsers: 0,
    uuid: uuidv4(),
    worlds_dropped: 0,
    timer: 0,
    loading: true,
  };
  // componentWillUnmount() {
  //   this.logout();
  // }
  componentDidMount() {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
    var inTenSeconds = new Date(new Date().getTime() + 10 * 1000);
    let cookieUUID = Cookies.get('uuid');
    if (cookieUUID != null) {
      this.state.uuid = cookieUUID.toString();
    } else {
      Cookies.set('uuid', this.state.uuid, { expires: inTenSeconds });
    }
    this.login();
    this.delayCheck();
  }

  delayCheck = async () => {
    await delay(300);
    this.setActiveUsers();
    this.setWorldsDropped();
    await delay(300);

    this.timerRefresh();
    this.loopingRefresh();
    this.setState({ loading: false });
  };

  timerRefresh = async () => {
    this.setState({
      timer: this.state.timer + 1,
    });
    await delay(1000);
    this.timerRefresh();
  };

  loopingRefresh = async () => {
    this.refreshToken();
    this.setActiveUsers();
    this.setWorldsDropped();
    var inTenSeconds = new Date(new Date().getTime() + 10 * 1000);
    Cookies.set('uuid', this.state.uuid, { expires: inTenSeconds });
    await delay(5 * 1000);
    this.loopingRefresh();
  };

  setActiveUsers = async () => {
    const { data, error } = await supabase
      .from('ActivePlayers')
      .select()
      .neq('uuid', this.state.uuid);
    if (data != null) this.setState({ activeUsers: data.length });
  };

  logout = async () => {
    await supabase.from('ActivePlayers').delete().eq('uuid', this.state.uuid);
  };

  setWorldsDropped = async () => {
    const { data } = await supabase.from('Global Stats').select();
    console.log('Worlds Dropped:' + JSON.stringify(data[0].worlds_dropped));
    this.state.worlds_dropped = data[0].worlds_dropped;
  };

  login = async () => {
    let { data, error } = await supabase
      .from('ActivePlayers')
      .select()
      .eq('uuid', this.state.uuid);
    if (error != null) {
      console.log('Error' + JSON.stringify(error));
    }

    if (data.length != 0) {
      console.log('User Found');
      this.refreshToken();
      return;
    } else {
      console.log('Making new user');
      let { data, error } = await supabase
        .from('ActivePlayers')
        .insert({ uuid: this.state.uuid });
      console.log('Data From Account Creation' + JSON.stringify(data));
      console.log('Error from Account Creation' + JSON.stringify(error));
    }
  };

  refreshToken = async () => {
    const { data, error } = await supabase
      .from('ActivePlayers')
      .update({ last_update: new Date() })
      .eq('uuid', this.state.uuid)
      .single();
  };

  // Render method.
  render() {
    return (
      <section id='centerSection'>
        {this.state.loading ? (
          <Loader></Loader>
        ) : (
          <div>
            <section id='topText'>
              <h1> You are Atlas.</h1>
              <h2> You hold up the sky.</h2>
            </section>
            <div id='bottomText'>
              <p id='mainText'>
                {' '}
                If no one is on this page, the sky will fall.
              </p>
              <p id='mainText'>
                There {this.state.activeUsers == 1 ? 'is' : 'are'}{' '}
                <span id='online-user-label'>{this.state.activeUsers}</span>{' '}
                other user{this.state.activeUsers == 1 ? '' : 's'} online.
              </p>
              <p id='mainText'>
                The sky has fallen{' '}
                <span id='total-skyfall-label'>
                  {this.state.worlds_dropped}
                </span>{' '}
                times.
              </p>
              <p id='mainText'>
                You have been holding the sky for{' '}
                <span id='active-time-label'>
                  {this.state.timer} second{this.state.timer != 1 ? 's' : ''}
                </span>
                .
              </p>
            </div>
          </div>
        )}
      </section>
    );
  }
}
